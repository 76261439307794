<template>
  <div>
    <div class="fetaured-wrap">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h2><span class="brand-color">Plans</span> <br /></h2>
          </div>
          <div class="plansGrid-wrap">
            <div class="mb-5">
              <div class="row" v-if="planCategories && planCategories.data">
                <div
                  class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12 mb-4"
                  v-for="(item, index) in planCategories.data"  :key="index"
                >
                  <div class="category-block card-block text-start plan-card "  @click="planPage(item)">
                    <a class="mb-3" >
                      <!-- <router-link :to="item.link"> -->
                        <img
                          v-if="item && planCategories.icon[item]"
                          :src="planCategories.icon[item]"
                          alt="Critical"
                          class="img-fluid plan-img"
                          :id="`plan-${index}`"
                        />
                       <div v-if="item == 'term_life'">Term Life</div>
                       <div class="text-capitalize" v-else>
                         {{item}}
                       </div>
                      <!-- </router-link> -->
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <location-permission />
  </div>
</template>

<script>
import axios from "axios";
import Api from "../includes/Api";
import LocationPermission from "./LocationPermission.vue";
export default {
  name: "LandingPage",
  components: {
    LocationPermission
  },
  data: () => ({
    homePlans: [],
    planCategories: null,
    landing_type: "",
  }),
  mounted() {
    let app = this;
    app.landing_type = window.localStorage.getItem("open_type");
    app.redirectionType = window.localStorage.getItem('redirectionType');
    app.getHomePlans();
  },
  methods: {
    planPage: function (planName) {
      let app = this
      planName == 'medical' ?  app.$store.dispatch("isPremierPlanSelected", true) :  app.$store.dispatch("isPremierPlanSelected", false)
      app.$router.push({
        name: 'plans',
        query: {
          type: planName
        }
      })
    },
    async fetchInfo() {
      let app = this;
      if (
        (location.host == process.env.VUE_APP_SITE_HOST_NAME &&
          app.landing_type == "agent" &&
          app.$route.params.landing) ||
        (location.host == process.env.VUE_APP_SITE_HOST_NAME &&
          app.landing_type == "group" &&
          app.$route.params.landing)
      ) {
        await Api.get(
          "/get-domainSite-infromation?site_domain=" +
            window.btoa(unescape(encodeURIComponent(location.host)))
        )
          .then((response) => {
            if (response.data.statusCode == 200) {
              if (app.landing_type !== "group") {
                window.localStorage.setItem("group_id", response.data.data.gid);
              }
              window.localStorage.setItem("portal_id", response.data.data.gid);
            }
          })
          .catch(() => {
            app.$router.push("/404");
          });
      }
    },
    async getHomePlans() {
      let app = this;
      await app.fetchInfo();
      let params = {}
      let url = `${process.env.VUE_APP_API_BASE}/get-common-menu`;
      if (app.redirectionType == 'direct-continue') {
        params = {
          agent_id: window.localStorage.getItem("agent_id"),
          group_id: window.localStorage.getItem("group_id"),
          eprocess: window.localStorage.getItem("eprocess"),
          open_type: window.localStorage.getItem("open_type"),
          portal_id: window.localStorage.getItem("portal_id"),
          enrollment_id: window.localStorage.getItem("tempId"),
        }
      } else {
        params = {
          agent_id: window.localStorage.getItem("agent_id"),
          group_id: window.localStorage.getItem("group_id"),
          eprocess: window.localStorage.getItem("eprocess"),
          open_type: window.localStorage.getItem("open_type"),
          portal_id: window.localStorage.getItem("portal_id"),
        }
      }

      Object.keys(params).forEach((k) => params[k] == null && delete params[k]);
      axios.get(url, { params: params })
          .then((res) => {
            app.planCategories = res.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },
  },
};
</script>

<style lang="scss">
.category-block {
  height: 100%;
  position: relative;
  padding-top: 2rem;
  padding-bottom: 2rem;
  img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}
</style>
